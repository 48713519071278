// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable
$white:  #fff !default;
$gray-100: #fafafa !default; // gray-lightest-o
$gray-200: #f6f6f6 !default; // gray-lightest
$gray-300: #eee !default; // gray-lighter
$gray-400: #ddd !default; // gray-lighter-o
$gray-500: #ccc !default; // gray-light-o
$gray-600: #999 !default; // gray-light
$gray-700: #666 !default; // gray
$gray-800: #595959 !default; // gray-obs
$gray-900: #333 !default; // gray-dark
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #527edb !default; // Boosted Functionnal
$indigo:  #6610f2 !default;
$purple:  #a885d8 !default; // Boosted illustration color
$pink:    #ffb4e6 !default; // Boosted illustration color
$red:     #cd3c14 !default; // Boosted Functionnal
$orange:  #f16e00 !default; // Boosted
$yellow:  #fc0 !default; // Boosted Functionnal
$green:   #32c832 !default; // Boosted Functionnal
$teal:    #50be87 !default; // Boosted illustration color
$cyan:    #4BB4E6 !default; // Boosted illustration color

$colors: () !default;
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);

$primary:       $orange !default;
$secondary:     $black !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-400 !default;
$dark:          $black !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
// $yiq-contrasted-threshold: 150 !default; // Boosted mod, contrast value is fixed with Wcag

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $black !default;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true !default;
$enable-rounded:            false !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer:   1.25rem !default; // 20px
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white !default;
$body-color:    $black !default;

// Links
//
// Style anchor elements.

$link-color:            #000 !default;
$link-decoration:       none !default;
$link-hover-color:      theme-color("primary") !default;
$link-hover-decoration: none !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 480px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop (target 1024 screen resolutions)
  lg: 980px,
  // Extra large screen / wide desktop (target 1280 screen resolutions)
  xl: 1220px,
  // Extra large screen / wide desktop (target 1440 screen resolutions)
  xxl: 1380px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 312px,
  sm: 468px,
  md: 744px,
  lg: 960px,
  xl: 1200px,
  xxl: 1320px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$container-fluid-margin-widths: (
  xs: 1.25%,
  sm: 1.25%,
  md: 1.5625%,
  lg: 3.125%,
  xl: 3.125%,
  xxl: 4.16667%
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           20px !default;
$grid-gutter-widths: (
  xs:                         10px,
  sm:                         10px,
  md:                         $grid-gutter-width,
  lg:                         $grid-gutter-width,
  xl:                         $grid-gutter-width,
  xxl:                        $grid-gutter-width
) !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              (20 / 18) !default;
$line-height-sm:              (20 / 14) !default;

$border-width:                2px !default;
$border-color:                $gray-200 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         $black !default;

$caret-width:                 .4375em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "HelvNeueOrange", "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.125) !default; // 18px
$font-size-sm:                ($font-size-base * .875) !default; // 14px

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.25 !default; // 20px

$h1-font-size:                ($font-size-base * 2.125) !default; // 34px
$h2-font-size:                ($font-size-base * 1.875) !default; // 30px
$h3-font-size:                ($font-size-base * 1.5) !default; // 24px
$h4-font-size:                ($font-size-base * 1.25) !default; // 20px
$h5-font-size:                ($font-size-base * 1.125) !default; // 18px
$h6-font-size:                $font-size-base !default; // 16px

$h1-font-size-mobile:         ($font-size-base * 1.25) !default; // 20px
$h2-font-size-mobile:         ($font-size-base * 1.125) !default; // 18px
$h3-font-size-mobile:         $font-size-base !default; // 16px
$h4-font-size-mobile:         ($font-size-base * .875) !default; // 14px
$h5-font-size-mobile:         ($font-size-base * .875) !default; // 14px
$h6-font-size-mobile:         ($font-size-base * .875) !default; // 14px

$headings-margin-bottom:      $spacer !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        700 !default;
$headings-line-height:        1.1 !default;
$headings-color:              inherit !default;

$display1-size:               3.75rem !default; // 60px
$display2-size:               3.125rem !default; // 50px
$display3-size:               2.5rem !default; // 40px
$display4-size:               2.125rem !default; // 34px

$display1-weight:             700 !default;
$display2-weight:             700 !default;
$display3-weight:             700 !default;
$display4-weight:             700 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              ($font-size-base * 1.25) !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-700 !default;

$blockquote-small-color:      $gray-700 !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             $gray-300 !default;
$hr-border-width:             ($border-width / 2) !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              none !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .3125rem !default;

$mark-bg:                     theme-color("primary") !default;

$hr-margin-y:                 $spacer !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .625rem .625rem .625rem 0 !default;//10px;
$table-cell-padding-sm:       .3125rem .3125rem .3125rem 0 !default;//5px;

$table-bg:                    $white !default;
$table-accent-bg:             $gray-200 !default;
$table-hover-bg:              $gray-300 !default; // #eee on white bg
$table-active-bg:             $gray-400 !default;

$table-border-width:          ($border-width / 2) !default; // 1px
$table-border-color:          $gray-500 !default;

$table-head-bg:               $white !default;
$table-head-color:            $black !default;

$table-dark-bg:               $black !default;
$table-dark-accent-bg:        $gray-900 !default;
$table-dark-hover-bg:         $gray-700 !default;
$table-dark-border-color:     $gray-500 !default;
$table-dark-color:            $white !default;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .5rem !default;
$input-btn-padding-x:         1.125rem !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
//$input-btn-focus-color:       rgba(theme-color("primary"), .25) !default;
$input-btn-focus-box-shadow:  none !default;

$input-btn-padding-y-sm:      .375rem !default;
$input-btn-padding-x-sm:      .625rem !default;
$input-btn-line-height-sm:    1.5 !default;

$input-btn-padding-y-lg:      .875rem !default;
$input-btn-padding-x-lg:      1.25rem !default;
$input-btn-line-height-lg:    1.5 !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-bold !default;
$btn-box-shadow:              none !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        1 !default;
$btn-active-box-shadow:       none !default;

$btn-primary-color:           #fff !default;
$btn-primary-bg:              theme-color("primary") !default;
$btn-primary-border-color:    $btn-primary-bg !default;
//$btn-primary-hover-color:     $btn-primary-color !default;
//$btn-primary-hover-bg:        #000 !default;
//$btn-primary-active-color:    $btn-primary-color !default;
//$btn-primary-active-bg:       $btn-primary-bg !default;

//$btn-secondary-color:         #000 !default;
//$btn-secondary-bg:            transparent !default;
//$btn-secondary-border-color:  $gray-500 !default;

//$btn-info-color:              #fff !default;
$btn-info-bg:                 #000 !default;
//$btn-info-border-color:       $btn-info-bg !default;
//$btn-info-hover-color:        #000 !default;
//$btn-info-hover-bg:           #fff !default;

//$btn-success-color:           #000 !default;
$btn-success-bg:              theme-color("success") !default;
//$btn-success-border-color:    $btn-success-bg !default;

//$btn-warning-color:           $btn-primary-color !default;
//$btn-warning-bg:              $btn-primary-bg !default;
//$btn-warning-border-color:    $btn-primary-border-color !default;

//$btn-danger-color:            #fff !default;
$btn-danger-bg:               theme-color("danger") !default;
//$btn-danger-border-color:     $btn-danger-bg !default;

$btn-link-disabled-color:     $gray-500 !default;
//$btn-link-disabled-black-bg-color: $gray-700 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              all .2s ease-in-out !default;


// Forms

$input-padding-y:             $input-btn-padding-y !default;
$input-padding-x:             .625rem !default; // Boosted mod input padding x is not the same as buttons padding-x!
$input-line-height:           $input-btn-line-height !default;

$input-padding-y-sm:          $input-btn-padding-y-sm !default;
$input-padding-x-sm:          $input-btn-padding-x-sm !default;
$input-line-height-sm:        $input-btn-line-height-sm !default;

$input-padding-y-lg:          $input-btn-padding-y-lg !default;
$input-padding-x-lg:          $input-btn-padding-x-lg !default;
$input-line-height-lg:        $input-btn-line-height-lg !default;

$input-bg:                    #fff !default;
$input-disabled-bg:           $gray-300 !default;

$input-color:                 #000 !default;
$input-border-color:          $gray-500 !default;
$input-border-width:          $input-btn-border-width !default;
$input-box-shadow:            none !default;

$input-border-radius:         $border-radius !default;
$input-border-radius-lg:      $border-radius-lg !default;
$input-border-radius-sm:      $border-radius-sm !default;

$input-focus-bg:              $input-bg !default;
$input-focus-border-color:    $gray-600 !default;
$input-focus-color:           $input-color !default;
$input-focus-width:           $input-btn-focus-width !default;
$input-focus-box-shadow:      $input-btn-focus-box-shadow !default;

$input-placeholder-color:     $gray-700 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:         $input-border-width * 2 !default !default;

$input-height-inner:          ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                calc(#{$input-height-inner} + #{$input-height-border}) !default;

// $input-height-inner-sm:       ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:             (($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2)) !default;

// $input-height-inner-lg:       ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:             (($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2)) !default;

$input-transition:            border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:        ($spacer * .5) !default;

$form-check-input-gutter:     1.25rem !default;
$form-check-input-margin-y:   .25rem !default;
$form-check-input-margin-x:   .25rem !default;

$form-check-inline-margin-x:  .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-group-margin-bottom:    $spacer !default;

$input-group-addon-color:        $input-color !default;
$input-group-addon-bg:           $gray-300 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter:       1.875rem !default;
$custom-control-spacer-x:     $spacer !default;

$custom-control-indicator-size:       1.25rem !default;
$custom-control-indicator-bg:         $white !default;
$custom-control-indicator-bg-size:    1rem !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-disabled-bg:        $gray-500 !default;
$custom-control-label-disabled-color:         #767676 !default;

$custom-control-indicator-checked-color:      $white !default;
$custom-control-indicator-checked-bg:         theme-color("primary") !default;
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-checked-border-color: theme-color("primary") !default; // Boosted mod controls have border

$custom-control-indicator-focus-box-shadow:   none !default;

$custom-control-indicator-active-color:       $white !default;
$custom-control-indicator-active-bg:          theme-color("primary") !default;
$custom-control-indicator-active-box-shadow:  none !default;

$custom-checkbox-indicator-border-radius:     $border-radius !default;
$custom-checkbox-indicator-icon-checked:      url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%202l-8%208-3-3-2%202%205%205L16%204l-2-2z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E") !default;

$custom-checkbox-indicator-indeterminate-bg:  theme-color("primary") !default;
//$custom-checkbox-indicator-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath stroke='%23fff' d='M1 4h6'/%3E%3C/svg%3E") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius:        50% !default;
$custom-radio-indicator-icon-checked:         str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y:          .4375rem !default;
$custom-select-padding-x:          .5rem !default;
$custom-select-height:             $input-height !default;
$custom-select-indicator-padding:  1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:        $input-btn-line-height !default;
$custom-select-color:              $input-color !default;
$custom-select-disabled-color:     $gray-600 !default;
$custom-select-bg:                 $white !default;
$custom-select-disabled-bg:        $gray-300 !default;
$custom-select-bg-size:            8px 10px !default; // In pixels because image dimensions
//$custom-select-indicator-color:    $gray-900 !default;
$custom-select-indicator:          url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M0%200h14L7%207%200%200z%22%2F%3E%3C%2Fsvg%3E") !default;
$custom-select-border-width:       $input-btn-border-width !default;
$custom-select-border-color:       $input-border-color !default;
$custom-select-border-radius:      $border-radius !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-box-shadow:   none !default;

$custom-select-font-size-sm:       75% !default;
$custom-select-height-sm:          $input-height-sm !default;

$custom-select-font-size-lg:       125% !default;
$custom-select-height-lg:          $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $gray-300 !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:            1rem !default;
$custom-range-thumb-height:           $custom-range-thumb-width !default;
$custom-range-thumb-bg:               $component-active-bg !default;
$custom-range-thumb-border:           0 !default;
$custom-range-thumb-border-radius:    1rem !default;
$custom-range-thumb-box-shadow:       0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;
$custom-range-thumb-active-bg:        lighten($component-active-bg, 35%) !default;

$custom-file-height:                2.5rem !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-btn-focus-box-shadow !default;

$custom-file-padding-y:             $input-padding-y !default;
$custom-file-padding-x:             $input-padding-x !default;
$custom-file-line-height:           $input-line-height !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-btn-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;


// Form validation
$form-feedback-margin-top:         $form-text-margin-top !default;
$form-feedback-font-size:          $small-font-size !default;
$form-feedback-valid-color:        theme-color("success") !default;
$form-feedback-invalid-color:      theme-color("danger") !default;

// Form validation icons
$form-icon-success-color: theme-color("success") !default;
$form-icon-success: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-warning-color: theme-color("warning") !default;
$form-icon-warning: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-danger-color: theme-color("danger") !default;
$form-icon-danger: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"), "#", "%23") !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem !default;
$dropdown-padding-y:             0 !default;
$dropdown-spacer:                -2px !default;
$dropdown-bg:                    #fff !default;
$dropdown-border-color:          #000 !default;
$dropdown-border-radius:         $border-radius !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            $gray-300 !default;
$dropdown-box-shadow:            none !default;

$dropdown-link-color:            $black !default;
$dropdown-link-hover-color:      $white !default;
$dropdown-link-hover-bg:         $black !default;

$dropdown-link-active-color:     $white !default;
$dropdown-link-active-bg:        $black !default;

$dropdown-link-disabled-color:   $gray-700 !default;

$dropdown-item-padding-y:        .625rem !default;
$dropdown-item-padding-x:        .625rem !default;

$dropdown-header-color:          #000 !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:           1000 !default;
$zindex-sticky:             0 !default;
$zindex-fixed:              1030 !default;
$zindex-modal-backdrop:     1040 !default;
$zindex-modal:              1050 !default;
$zindex-popover:            1060 !default;
$zindex-tooltip:            1070 !default;
$zindex-o-scroll-up:        1080 !default; // Boosted mod

// Navs

$nav-link-padding-y:            ($spacer * .5) !default;
$nav-link-padding-x:            $spacer !default;
$nav-link-disabled-color:       $gray-700 !default;
$nav-link-disabled-hover-bg:    transparent !default;

$nav-tabs-border-color:                 #000 !default;
$nav-tabs-border-width:                 $border-width !default;
$nav-tabs-border-radius:                $border-radius !default;
$nav-tabs-link-hover-border-color:      #000 !default;
$nav-tabs-link-hover-color:             $white !default;
$nav-tabs-link-hover-bg:                $black !default;
$nav-tabs-link-active-color:            #000 !default;
$nav-tabs-link-active-bg:               $body-bg !default;
$nav-tabs-link-active-border-color:     #000 !default;

$nav-pills-border-radius:     $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg:    $component-active-bg !default;

$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              ($spacer / 2) !default;

// Navbar

$navbar-padding-y:                  0 !default;
$navbar-padding-x:                  0 !default;
$navbar-expanded-padding-y:         1.5625rem !default;
$navbar-expanded-padding-x:         0 !default;
$navbar-expanded-minimized-padding-y: 1.25rem !default;

// Boosted mod
// adapt navbar padding when mobile view
//$navbar-collapsed-padding-y:        ($spacer / 4) !default;
//$navbar-collapsed-padding-x:        0 !default;
$navbar-nav-link-padding-x:         0 !default;
$navbar-height:                     6.25rem !default;
$navbar-brand-logo-height:          1.875rem !default;
$navbar-brand-logo-expanded-height: 3.125rem !default;
$navbar-brand-logo-minimized-height: 2.5rem !default;

// $navbar-height-minimized:           5rem !default;
// $navbar-height-sm:                  3.125rem !default;
$navbar-supra-height:               2.5rem !default;
// end mod

$navbar-brand-font-size:            $font-size-sm !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-height:               ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y:            0 !default;

$navbar-toggler-padding-y:           .5rem !default;
$navbar-toggler-padding-x:           .5rem !default;
$navbar-toggler-font-size:           $font-size-base !default;
$navbar-toggler-border-radius:       $btn-border-radius !default;
$navbar-toggler-icon-size:           1.25rem !default;

$navbar-dark-color:                 $white !default;
$navbar-dark-hover-color:           theme-color("primary") !default;
$navbar-dark-active-color:          $navbar-dark-hover-color !default;
$navbar-dark-disabled-color:        $gray-400 !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$navbar-dark-color}' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-icon-hover-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 1024 1024' width='20px' height='20px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#000' d='M106.906 754.176c-10.957-2.15-21.299-10.65-26.419-21.402-2.765-6.042-3.174-7.782-3.174-15.974 0-8.397 0.307-9.83 3.379-16.077 4.301-8.704 10.138-14.541 18.637-18.637l6.656-3.174h812.032l6.656 3.174c8.397 4.096 14.848 10.445 18.637 18.432 9.114 19.456 1.024 41.574-18.637 50.995l-6.656 3.174-403.456 0.102c-221.901 0.102-405.402-0.205-407.654-0.614zM105.37 497.766c-10.65-2.867-19.251-10.035-24.678-20.582-2.97-5.53-3.277-7.066-3.277-15.872-0.102-8.806 0.205-10.342 3.072-16.384 3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.277c7.475 3.686 9.216 5.018 14.438 11.571 5.53 6.861 7.578 13.312 7.578 23.552 0 7.578-0.41 9.523-3.174 15.36-3.994 8.499-10.342 14.848-18.842 18.842l-6.656 3.174-403.968 0.205c-317.85 0.102-404.992-0.102-408.678-1.126zM104.96 241.766c-11.366-3.584-19.354-10.547-24.474-21.094-2.765-5.939-3.174-7.68-3.174-15.872s0.41-9.933 3.174-15.872c3.994-8.397 10.342-14.746 18.842-18.842l6.656-3.174h812.032l6.656 3.174c8.499 3.994 14.848 10.342 18.842 18.842 2.867 5.939 3.174 7.68 3.174 15.872s-0.307 9.933-3.174 15.872c-3.994 8.397-10.342 14.746-18.842 18.842l-6.656 3.174-404.48 0.205c-324.915 0.102-405.299-0.102-408.576-1.126z'/%3E%3C/svg%3E"), "#", "%23") !default;

$navbar-dark-toggler-border-color:  rgba(255, 255, 255, .1) !default;

$navbar-light-color:                $black !default;
$navbar-light-hover-color:          $navbar-dark-hover-color !default;
$navbar-light-active-color:         $navbar-dark-active-color !default;
$navbar-light-disabled-color:       $navbar-dark-disabled-color !default;
$navbar-light-toggler-icon-bg:      $navbar-dark-toggler-icon-bg !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

// Pagination

$pagination-padding-y:                ($spacer / 2) !default;
$pagination-padding-x:                .8rem !default;
$pagination-padding-y-sm:             .375rem !default;
$pagination-padding-x-sm:             .625rem !default;
$pagination-padding-y-lg:             .875rem !default;
$pagination-padding-x-lg:             1.125rem !default;
$pagination-line-height:              1 !default;

$pagination-color:                     #000 !default;
$pagination-bg:                        #fff !default;
$pagination-border-width:              $border-width !default;
$pagination-border-color:              transparent !default;
$pagination-focus-box-shadow:          $input-btn-focus-box-shadow !default; // Boosted mod not used
$pagination-focus-outline:             0 !default;

$pagination-hover-color:               $black !default;
$pagination-hover-bg:                  $white !default;
$pagination-hover-border-color:        $black !default;

$pagination-active-color:              $white !default;
$pagination-active-bg:                 $black !default;
$pagination-active-border-color:       $black !default;

$pagination-disabled-color:            $gray-500 !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border-color:     $gray-500 !default;

$pagination-icon-prev: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%2F%3E%3C%2Fsvg%3E") !default;
$pagination-icon-prev-disabled: url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%209%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%20fill%3D%22%23DDD%22%2F%3E%3C%2Fsvg%3E") !default;
$pagination-icon-prev-active: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M9%202L7%200%200%207l7%207%202-2-5-5%205-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E") !default;
$pagination-icon-next: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%2F%3E%3C%2Fsvg%3E") !default;
$pagination-icon-next-disabled: url("data:image/svg+xml;charset=utf8, %3Csvg%20viewBox%3D%220%200%209%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%20fill%3D%22%23DDD%22%2F%3E%3C%2Fsvg%3E") !default;
$pagination-icon-next-active: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2014%22%3E%3Cpath%20d%3D%22M0%202l2-2%207%207-7%207-2-2%205-5-5-5z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E") !default;
// Boosted Var
$pagination-min-width: 2.5rem !default;
$pagination-min-width-lg: 3.125rem !default;
$pagination-min-width-sm: 1.875rem !default;

// Jumbotron

$jumbotron-padding:              ($spacer * 2) !default;
$jumbotron-bg:                   #fff !default;


// Cards

$card-spacer-y:            .75rem !default;
$card-spacer-x:            1.25rem !default;
$card-border-width:        $border-width !default;
$card-border-radius:       $border-radius !default;
$card-border-color:        #000 !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:              #fff !default;
$card-bg:                  #fff !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin:         ($grid-gutter-width / 2) !default;
$card-deck-margin:         ($spacer * 2) !default;

$card-columns-count:        3 !default;
$card-columns-gap:          1.25rem !default;
$card-columns-margin:       ($spacer * 2) !default;


// Tooltips

$tooltip-font-size:           $font-size-sm !default;
$tooltip-max-width:           200px !default;
$tooltip-color:               #000 !default;
$tooltip-bg:                  $gray-400 !default;
$tooltip-border-radius:       $border-radius !default;
$tooltip-opacity:             .9 !default;
$tooltip-padding-y:           $spacer !default;
$tooltip-padding-x:           $spacer !default;
$tooltip-margin:              .3125rem !default;
$tooltip-font-weight:         $font-weight-bold !default;

$tooltip-arrow-width:         .625rem !default;
$tooltip-arrow-height:        .625rem !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Popovers

$popover-font-size:                   $font-size-sm !default;
$popover-bg:                          $gray-400 !default;
$popover-max-width:                   276px !default;
$popover-border-width:                $border-width !default;
$popover-border-color:                $gray-400 !default;
$popover-border-radius:               $border-radius-lg !default;
$popover-box-shadow:                  none !default;
$popover-font-weight:                 $font-weight-bold !default;

$popover-header-bg:                   $popover-bg !default;
$popover-header-color:                $headings-color !default;
$popover-header-padding-y:            .625rem !default;
$popover-header-padding-x:            .625rem !default;

$popover-body-color:                  $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:                 .625rem !default;
$popover-arrow-height:                .3125rem !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-color:           $popover-border-color !default;


// Badges

$badge-font-size:             .875rem !default;
$badge-font-weight:           $font-weight-bold !default;
$badge-padding-y:             .1875rem !default;
$badge-padding-x:             .625rem !default;
$badge-border-radius:         $border-radius !default;

$badge-pill-padding-x:        .625rem !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:    $border-radius !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:         .625rem !default;

$modal-dialog-margin:         .625rem !default;
$modal-dialog-margin-y-sm-up: 1.875rem !default;

$modal-title-line-height:     $line-height-base !default;

$modal-content-bg:               $white !default;
$modal-content-border-color:     $black !default;
$modal-content-border-width:     $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-box-shadow-xs:    none !default;
$modal-content-box-shadow-sm-up: none !default;

$modal-backdrop-bg:           $gray-900 !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   #000 !default;
$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width:   $modal-content-border-width !default;
$modal-footer-border-width:   $modal-header-border-width !default;
$modal-header-padding:        .625rem !default;

$modal-lg:                    700px !default;
$modal-md:                    700px !default;
$modal-sm:                    300px !default;

$modal-transition:            transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:             1.25rem !default;
$alert-padding-x:             1.25rem !default;
$alert-margin-bottom:         $spacer !default;
$alert-border-radius:         $border-radius !default;
$alert-link-font-weight:      $font-weight-bold !default;
$alert-border-width:          0 !default;

$alert-bg-level:              0 !default;
$alert-border-level:          0 !default;
// $alert-color-level:        0 !default; // Not used by boosted


// Progress bars

$progress-height:             $spacer !default;
$progress-font-size:          1rem !default;
$progress-bg:                 $gray-500 !default;
$progress-border-radius:      $border-radius !default;
$progress-box-shadow:         none !default;
$progress-bar-color:          #000 !default;
$progress-bar-bg:             theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition:       width .6s ease !default;

// List group

$list-group-bg:                 $white !default;
$list-group-border-color:       $black !default;
$list-group-border-width:       $border-width !default;
$list-group-border-radius:      $border-radius !default;

$list-group-item-padding-y:      .625rem !default;
$list-group-item-padding-x:      .625rem !default;

$list-group-hover-bg:           $gray-200 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border-color:$list-group-active-bg !default;

$list-group-disabled-color:      $gray-500 !default;
$list-group-disabled-bg:         #fff !default;

$list-group-action-color:          #000 !default;
$list-group-action-hover-color:    theme-color("primary") !default;

$list-group-action-active-color:   $body-color !default;
$list-group-action-active-bg:      $gray-300 !default;


// Image thumbnails

$thumbnail-padding:           .625rem !default;
$thumbnail-bg:                $body-bg !default;
$thumbnail-border-width:      $border-width !default;
$thumbnail-border-color:      #000 !default;
$thumbnail-border-radius:     $border-radius !default;
$thumbnail-box-shadow:        none !default;


// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color:     $gray-900 !default;


// Breadcrumbs

$breadcrumb-padding-y:          .625rem !default;
$breadcrumb-padding-x:          0 !default;
$breadcrumb-item-padding:       .625rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                 #fff !default;
$breadcrumb-divider-color:      #000 !default;
$breadcrumb-active-color:       theme-color("primary") !default;
$breadcrumb-divider:            "\e93e" !default;
$breadcrumb-font-family:        icon-orange !default;
$breadcrumb-divider-font-size:  .625rem !default;
$breadcrumb-font-size:          $font-size-sm !default;
$breadcrumb-line-height:        $line-height-sm !default;

$breadcrumb-border-radius:          $border-radius !default;


// Carousel

$carousel-control-color:                      #000 !default;
$carousel-control-width:                      1.875rem !default;
$carousel-control-opacity:                    .5 !default;

$carousel-indicator-width:                    30px !default;
$carousel-indicator-height:                   3px !default;
$carousel-indicator-spacer:                   3px !default;
$carousel-indicator-active-bg:                theme-color("primary") !default;

$carousel-caption-width:                      70% !default;
$carousel-caption-color:                      #000 !default;

$carousel-control-icon-width:                 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition:           transform .6s ease !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Close

$close-font-size:             $font-size-base * 1.5 !default;
$close-font-weight:           $font-weight-bold !default;
$close-color:                 #000 !default;
$close-text-shadow:           0 1px 0 #fff !default;

// Code

$code-font-size:              87.5% !default;
$code-color:                  $gray-900 !default;

$kbd-padding-y:               .0625rem !default;
$kbd-padding-x:               .0625rem !default;
$kbd-font-size:               $code-font-size !default;
$kbd-color:                   #000 !default;
$kbd-bg:                      $gray-300 !default;

$pre-color:                   $gray-900 !default;
$pre-scrollable-max-height:   340px !default;

// Printing
$print-page-size:                   a3 !default;
$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;

//////// BOOSTED SPECIFIC ////////
// Accordions

$accordion-arrow-icon: url("data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%207%22%3E%3Cpath%20d%3D%22M7%207L0%200h14L7%207z%22%2F%3E%3C%2Fsvg%3E") !default;
$o-sortable-hover-icon: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2017%22%3E%3Cpath%20d%3D%22M%207%2C17%200%2C10%20h%2014%20l%20-7%2C7%20z%22%2F%3E%3Cpath%20d%3D%22M%207%2C0%2014%2C7%200%2C7%207%2C0%20z%22%2F%3E%3C%2Fsvg%3E") !default;

// arrow Links

$o-link-arrow-separator-padding-vertical: 0 !default;
$o-link-arrow-separator-padding-horizontal: .625rem !default;
$o-link-arrow-color: #000 !default;
$o-link-arrow-separator-size: .625rem !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// For REM size

// Iconography
//
// Load fonts from this directory.
$icon-font-path:          "../fonts/" !default;

// o-scroll-up
//
//
$o-scroll-up-height: 2.5rem !default; //30px
$o-scroll-up-width: $o-scroll-up-height !default; // 30px
//$o-scroll-up-font-size: 1.25rem !default; // 20px

// Navbar
//
//


// Mega-Menu
//
//

//$megamenu-border:                   1px solid $gray-900 !default;
//$megamenu-hover-color:              theme-color("primary") !default;
//$megamenu-position:                 fixed !default;

// Navs
//

// Tabs


// Pills
$nav-pills-focus-link-bg: theme-color("primary") !default;
$nav-pills-focus-link-color: #fff !default;


// Local navigation
//$nav-local-arrow-color: #fff !default;
$nav-local-arrow-width: 10px !default;
$nav-local-border-color: $gray-500 !default;

//$nav-local-arrow-outer-width:           ($nav-local-arrow-width + 1) !default;
//$nav-local-arrow-outer-color:           $nav-local-border-color !default;

// Step process bar
$o-stepbar-height: 2.5rem !default;
$o-stepbar-arrow-width: 1rem !default;
//$o-stepbar-arrow-ratio: 3 / 8 !default;

$o-stepbar-mobile-padding-vertical: .3125rem !default;
$o-stepbar-mobile-arrow-width: .75rem !default;
$o-stepbar-mobile-arrow-height: 1.875rem !default;

// focus state mixin
@mixin focus {
  &:focus {
    @content;
  }
}

@mixin active {
  &:active {
    @content;
  }
}
